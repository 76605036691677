import { useEffect, useState } from "react";
import {
  InventoriesClient,
  ApiResponse,
  ApiException,
  HttpResponseType,
  LocationsClient,
  KitTypesClient,
  ExportTransactionsRequestDTO,
  LocationDTO,
  KitTypeDTO,
  FileResponse,
  FilteredReportRequestDTO
} from "api/clients";
import DownloadFile from "api/downloadFile";
import Provinces from "common/provinces"
import { getReasonPhrase } from "http-status-codes";
import Loading from "components/common/Loading";
// import { Link } from "react-router-dom";
import ApiResponseAlert from "components/common/ApiResponseAlert";
import SCardHeader from "components/common/CardHeader";
import { CCard, CCardBody } from "@coreui/react";
import WrappedPage from "components/pages/WrappedPage";
import { SelectOption, SelectOptionGroup } from "common/selectOption";
import { DateTime } from "luxon";
import ReportFilters from "../reporting/Filters";

export default function TransactionsReport() {
  const [ready, setReady] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [locations, setLocations] = useState<SelectOptionGroup[]>([])
  const [kitTypes, setKitTypes] = useState([] as SelectOption[])
  const [inventoryClient] = useState(new InventoriesClient());
  const [locationsClient] = useState(new LocationsClient());
  const [kitTypesClient] = useState(new KitTypesClient());
  const [filters] = useState(FilteredReportRequestDTO.fromJS({
    startDate: DateTime.local().plus({ month: -1 }),
    endDate: DateTime.local()
  }))
  const [actionResult, setActionResult] = useState(
    undefined as ApiResponse | undefined
  );

  let breadcrumbs = [
    {
      to: "/inventory",
      label: "Inventory"
    },
    {
      label: "Export Transactions"
    }
  ];

  useEffect(() => {
    async function getData() {
      let locationsPromise = locationsClient
        .getAll()
        .then((result: ApiResponse) => {
          var locations = result.data! as LocationDTO[]
          setLocations(Provinces.map(x => {
            return {
              label: x.label,
              value: x.value,
              options: locations.filter(y => y.enabled && x.value === y.provinceCode).map(y => {
                return { value: y.id, label: y.names['en'] }
              })
            }
          }))
        })
        .catch((result: ApiException) => {
          setActionResult(
            ApiResponse.fromJS({
              responseType: HttpResponseType.Error,
              messages: [
                "Error getting locations: " + getReasonPhrase(result.status)
              ]
            })
          );
        });

      let kitTypesPromise = kitTypesClient
        .getAll()
        .then((result: ApiResponse) => {
          setKitTypes([{ label: 'Any', value: null }, ...(result.data! as KitTypeDTO[]).filter(x => x.enabled).map(x => { return { label: x.name, value: x.id } })])
        })
        .catch((result: ApiException) => {
          setActionResult(
            ApiResponse.fromJS({
              responseType: HttpResponseType.Error,
              messages: [
                "Error getting kit types: " + getReasonPhrase(result.status)
              ]
            })
          );
        });

      await Promise.all([ locationsPromise, kitTypesPromise ])

      setReady(true);
    }

    getData();
  }, [locationsClient, kitTypesClient]);

  function getExport (filters: ExportTransactionsRequestDTO): void {

    if(exporting) {
      return;
    }

    setExporting(true);

    inventoryClient.exportInventoryTransactions(filters)
      .then((file: FileResponse | null) => {
        DownloadFile(file)
      })
      .catch((reason: ApiException) => {
        if(reason !== undefined) {
          setActionResult(ApiResponse.fromJS({
            responseType: HttpResponseType.Error,
            messages: [
              "Error getting inventory transactions: " + getReasonPhrase(reason.status)
            ]
          }))
        }
      })
      .finally(() => {
        setExporting(false)
      });
  };

  return (
    <WrappedPage breadcrumbs={breadcrumbs}>
      <CCard>
        <SCardHeader
          title="Export Transactions"
        ></SCardHeader>
        <CCardBody>
          {!ready ? (
            <Loading color="primary" label="Loading"></Loading>
          ) : null}
          {ready && actionResult !== undefined && (
            <ApiResponseAlert data={actionResult}></ApiResponseAlert>
          )}
          {ready && 
            <ReportFilters handleSubmit={getExport} kitTypes={kitTypes} locations={locations} initialFilters={filters} isSearching={exporting}></ReportFilters>
         }
        </CCardBody>
      </CCard>
    </WrappedPage>
  );
}