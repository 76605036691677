import {
  LocationDTO
} from "api/clients";
import { SupportedLanguages } from "common/constants";
import Provinces from "common/provinces"
import { Form, WrappedInput, Checkbox, WrappedSelect } from "components/common/Form";

type EditLocationFormProps = {
  location: LocationDTO;
  onSubmit: any;
};

export default function LocationEditForm({ onSubmit, location }: EditLocationFormProps) {

  console.log(location)

  return (
    <div className="mb-3">
      <Form defaultValues={location} onSubmit={onSubmit} autoComplete="off">
        <div className="row">
          {SupportedLanguages.map(x => 
            <div className="col-12 col-lg-6" key={x}>
              <WrappedInput name={`names.${x}`} label={`Name (${x.toUpperCase()})`} rules={{required: true }} />
              <WrappedInput name={`addresses.${x}`} label={`Address (${x.toUpperCase()})`} rules={{required: true }} />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <WrappedSelect options={Provinces} name="provinceCode" label="Province" rules={{ required: true }} />
          </div>
          <div className="col-12 col-lg-6">
            <Checkbox name="enabled" label="Enabled" checked={location.enabled} id="IsEnabled" disabled={true} />
            <Checkbox name="isPickup" label="Pickup Location" checked={location.isPickup} id="IsPickup" />
            <Checkbox name="isCommunityLink" label="Community Link" checked={location.isCommunityLink} id="IsCommunityLink" />
          </div>
        </div>
        <div className="text-right">
          <input className="btn btn-primary" type="submit" />
        </div>
        <hr />
      </Form>
    </div>
  );
}
