import { useEffect, useRef, useState } from "react";
import {
  LocationsClient,
  KitTypesClient,
  InventoriesClient,
  ApiResponse,
  LocationDTO,
  KitTypeDTO,
  HttpResponseType,
  ApiException,
  DisposalRequestDTO
  //HttpResponseType
} from "api/clients";
import { CCard, CCardBody } from "@coreui/react";
import ApiResponseAlert from "components/common/ApiResponseAlert";
import WrappedPage from "components/pages/WrappedPage";
import SCardHeader from "components/common/CardHeader";
import Loading from "components/common/Loading";
// import { useHistory } from "react-router";
import { Form, WrappedDatePicker, WrappedInput, WrappedSelect, WrappedTextbox } from "components/common/Form";
import { getReasonPhrase } from "http-status-codes";
import { SelectOption } from "common/selectOption";
import { DateTime } from "luxon"

export default function DisposeInventory() {
  // const history = useHistory();
  const alertRef = useRef<null | HTMLDivElement>(null)
  const executeScrollToAlert = () => { 
    if(alertRef.current !== null) {
      const yOffset = -75; 
      const y = alertRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({top: y, behavior: 'smooth'});
    } 
  }

  const [ready, setReady] = useState(false)
  const [saving, setSaving] = useState(false)
  //const [shipment, setShipment] = useState(new PickUpShipmentDTO())
  const [locations, setLocations] = useState([] as SelectOption[])
  const [kitTypes, setKitTypes] = useState([] as SelectOption[])
  const [inventoryClient] = useState(new InventoriesClient());
  const [locationsClient] = useState(new LocationsClient());
  const [kitTypesClient] = useState(new KitTypesClient());
  const [actionResult, setActionResult] = useState(
    undefined as ApiResponse | undefined
  );

  let breadcrumbs = [
    {
      to: "/inventory",
      label: "Inventory"
    },
    {
      label: "Dispose Inventory"
    }
  ];

  // default to have 3 details sections show up on the form
  let defaultDisposal = DisposalRequestDTO.fromJS({
    disposalDate: new Date(),
    quantity: 0
  });

  useEffect(() => {
    async function getData() {
      let locationsPromise = locationsClient
        .getAll()
        .then((result: ApiResponse) => {
          setLocations((result.data! as LocationDTO[]).filter(x => x.enabled).map(x => { return { label: x.names['en'], value: x.id } }))
        })
        .catch((result: ApiException) => {
          setActionResult(
            ApiResponse.fromJS({
              responseType: HttpResponseType.Error,
              messages: [
                "Error getting locations: " + getReasonPhrase(result.status)
              ]
            })
          );
        });

      let kitTypesPromise = kitTypesClient
        .getAll()
        .then((result: ApiResponse) => {
          setKitTypes((result.data! as KitTypeDTO[]).filter(x => x.enabled).map(x => { return { label: x.name, value: x.id } }))
        })
        .catch((result: ApiException) => {
          setActionResult(
            ApiResponse.fromJS({
              responseType: HttpResponseType.Error,
              messages: [
                "Error getting kit types: " + getReasonPhrase(result.status)
              ]
            })
          );
        });

      await Promise.all([ locationsPromise, kitTypesPromise ])

      setReady(true);
    }

    getData();
  }, [locationsClient, kitTypesClient]);

  function onSubmit(data: DisposalRequestDTO) {
    setSaving(true)

    data.disposalDate = DateTime.fromMillis((data.disposalDate as any).ts)
    data.expiryDate = DateTime.fromMillis((data.expiryDate as any).ts)

    inventoryClient
      .disposeInventory(data)
      .then((response: ApiResponse) => {
        setActionResult(response);
        /* if (response.data && "id" in response.data) {
          history.push("/inventory");
        } */
      })
      .catch((reason: any) => {

        if(reason.title !== undefined && reason.title.length > 0) {
          setActionResult(
            ApiResponse.fromJS({
              messages: [reason.title],
              responseType: HttpResponseType.Error
            })
          );
        } else {
          setActionResult(
            ApiResponse.fromJS({
              messages: [reason],
              responseType: HttpResponseType.Error
            })
          );
        }
        
        console.error(reason);
      })
      .finally(() => {
        executeScrollToAlert();
        setSaving(false)
      });
  }

  return (
    <WrappedPage breadcrumbs={breadcrumbs}>
      {!ready && <Loading color="primary" label="Loading Disposal Screen"></Loading>}
      {ready && <CCard>
        <SCardHeader title="Inventory - Disposal"></SCardHeader>
        <CCardBody>
          {actionResult !== undefined && (
            <ApiResponseAlert data={actionResult} innerRef={alertRef}></ApiResponseAlert>
          )}
          <Form defaultValues={defaultDisposal} onSubmit={onSubmit} autoComplete="off">
            <div className="row">
              <div className="col-12 col-lg-6">
                <WrappedSelect name="locationID" label="Location" options={locations} rules={{required: true}} />
              </div>
              <div className="col-12 col-lg-6">
                <WrappedSelect name="kitTypeID" label="Kit Type" options={kitTypes} rules={{required: true}} />
              </div>
            </div>
            <p>
              <span className="font-weight-bold">Instructions:</span> Please complete this test kit disposal record when disposing of expired or damaged test kits. Send the completed log by email to REACH (<a href="mailto:reachnexus@unityhealth.to">reachnexus@unityhealth.to</a>) within two (2) weeks of kit disposal.
            </p>
            <p>
              <span className="font-weight-bold">Reminders:</span> Damaged or expired test kits must be disposed of as per manufacturer's guidance. Lead test kit expiry date is printed on the back of the kit pouch as yyyy-mm-dd. Test kits within one month of the expiry date or damaged kits must NOT be distributed to participants. Do NOT distribute or use damaged or expired test kits as test performance and accuracy of results cannot be guaranteed.
            </p>

            <div className="row">
              <div className="col-12 col-lg-6">
                <WrappedInput type="text" name="lotNumber" label="Lot Number" rules={{required: true}} />
                <WrappedDatePicker name="expiryDate" label="Expiry Date" rules={{required: true}} />
                <WrappedInput name="quantity" type="number" step="1" label="Quantity" rules={{required: true, min: { value: 1, message: "Minimum of 1 required" } }} />
              </div>
              <div className="col-12 col-lg-6">
                <WrappedInput type="text" name="qrCodes" label="QR codes (range)" />
                <WrappedDatePicker name="disposalDate" label="Disposal Date" rules={{required: true}} />
              </div>
            </div>

            <WrappedTextbox name="notes" label="Notes" />

            <div className="text-right">
              <button className="btn btn-primary" type="submit" disabled={saving}>{!saving ? "Submit" : "Saving..."}</button>
            </div>
          </Form>
        </CCardBody>
      </CCard>}
    </WrappedPage>
  );
}
