import { useEffect, useState } from 'react'
import { toast } from "react-toastify";
import { CCard, CCardBody } from "@coreui/react";
import WrappedPage from "components/pages/WrappedPage";
import ReportingTabs from "./Tabs"
import { ApiException, ApiResponse, FileResponse, FilteredReportRequestDTO, HttpResponseType, LocationDTO, LocationsClient, ReportingClient } from "api/clients";
import DownloadFile from "api/downloadFile";
import ReportFilters from "./Filters"
import { SelectOptionGroup } from 'common/selectOption';
import { getReasonPhrase } from 'http-status-codes';
import Loading from 'components/common/Loading';
import ApiResponseAlert from 'components/common/ApiResponseAlert';
import Provinces from "common/provinces"


interface KitsOrderedProps {}
export default function SurveysVsScanned(props: KitsOrderedProps) {
    const now = new Date();

    const [locationClient] = useState(new LocationsClient())
    const [reportingClient] = useState(new ReportingClient())

    const [ready, setReady] = useState(false)
    const [filters] = useState(FilteredReportRequestDTO.fromJS({
        startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6).toISOString(),
        endDate: now.toISOString()
      }))
    const [searching, setSearching] = useState(false)

    const [locations, setLocations] = useState<SelectOptionGroup[]>([])
    const [actionResult, setActionResult] = useState<ApiResponse | undefined>(undefined);

    let handleSubmit = (filters: FilteredReportRequestDTO) => {

        setSearching(true)
    
        reportingClient.getSurveysVsScannedReportCsv(filters)
          .then((resp: FileResponse | null) => {
            DownloadFile(resp);
    
            setSearching(false)
          }).catch((err: any) => {
            toast.error("Error getting report, please try again.");
            console.error('Error getting report:', err)
          });
      };

    useEffect(() => {
        async function getData() {
            setReady(false)
        
            let locationsPromise = locationClient
            .getAll()
            .then((result: ApiResponse) => {
                var locations = result.data! as LocationDTO[]
    
                setLocations(Provinces.map(x => {
                return {
                    label: x.label,
                    value: x.value,
                    options: locations.filter(y => y.enabled && x.value === y.provinceCode).map(y => {
                    return { value: y.id, label: y.names['en'] }
                    })
                }
                }))
            })
            .catch((result: ApiException) => {
                setActionResult(
                ApiResponse.fromJS({
                    responseType: HttpResponseType.Error,
                    messages: [
                    "Error getting locations: " + getReasonPhrase(result.status)
                    ]
                })
                );
            });
    
            
    
            await Promise.all([ locationsPromise ])
    
            setReady(true);
        }
        getData();
    }, [locationClient]);
    return (
        <>
          <ReportingTabs></ReportingTabs>
          <WrappedPage breadcrumbs={[{ label: "Reporting" }]}>
          {!ready && <Loading color="primary" label="Loading Surveys vs Claimed Form"></Loading>}
          {ready &&
            <CCard>
              <CCardBody>
              {actionResult !== undefined && (
                <ApiResponseAlert data={actionResult}></ApiResponseAlert>
              )}
                <ReportFilters handleSubmit={handleSubmit} locations={locations} initialFilters={filters} isSearching={searching}></ReportFilters>
              </CCardBody>
            </CCard>
          }
          </WrappedPage>
        </>
      )
}