import { LocationDTO } from "api/clients"
import { SelectOptionGroup } from "./selectOption"
import Provinces from "./provinces"

export const OtherLocations = {
    PharmacyDotCa: { value: -1, label:  'Pharmacy.ca DC' },
    Other: { value: -2, label: 'Other' },
    Supplier: { value: -3, label:  'Supplier' }
}

export const generateLocationOptions = (locations: LocationDTO[], includeOthers: boolean): SelectOptionGroup[] => {
    let options = Provinces.map(x => {
        return {
            label: x.label,
            value: x.value,
            options: locations.filter(y => y.enabled && x.value === y.provinceCode).map(y => {
                return { value: y.id, label: y.names['en'] }
            })
        }
    })

    if(includeOthers) {
        options.push({
            label: "Other",
            value: "Other",
            options: Object.values(OtherLocations)
        })
    }

    return options
}